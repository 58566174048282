<template>
  <v-menu
    v-model="showMenu"
    offset-y
    width="370"
    max-width="370"
    bottom
    nudge-left="20"
  >

    <template v-slot:activator="{on, attrs}">
      <v-text-field
        clearable
        id="search-bar"
        rounded
        :placeholder="$t('view.search.input')"
        class="my-0 ml-2 pr-0 mr-0"
        :style="style"
        @focus="showMenu = search_ !== '' && items.length > 0"
        clear-icon="fa fa-times"
        background-color="white"
        @input="searchAPI"
        v-model="search_" />
    </template>

    <v-card>
      <v-list v-if="!isLoading && items.length > 0">
        <template v-for="item of items">
          <v-list-item @click="onSelect(item)">
            <v-list-item-icon
              v-if="item.nlp"
              color="primary">
              <v-icon>far fa-microphone</v-icon>
            </v-list-item-icon>
            <v-list-item-icon
              v-else
              color="primary">
              <v-icon>far fa-location</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.label"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item disabled dense>
          <v-row align="start" class="d-block">
            <v-img  position="right" max-height="15" contain src="@/assets/pictures/powered_by_google_on_white.png" />
          </v-row>
        </v-list-item>
      </v-list>

      <Skeleton
        v-else-if="isLoading"
        :occurrence="3"
        type="list-item" />

      <v-list v-else>
        <v-list-item>Pas de résultat</v-list-item>
      </v-list>
    </v-card>

  </v-menu>

</template>

<script>
import {v4 as uuidv4} from "uuid";
import Skeleton from "@/components/Common/Skeleton";

export default {
  name: "SearchBar",

  props: {
    value: {type: Object, default: () => null}
  },

  components: {
    Skeleton
  },

  mixins: [],

  data() {
    return {
      showMenu: false,
      search_: null,
      search: null,
      model: null,
      items: [],
      isLoading: false,
      _timerID: null,
      session_token: uuidv4()
    }
  },

  methods: {
    clearSearch() {
      this.items = [];
      this.search_ = "";
      this.showMenu = false;
    },

    onSearch() {

      if (this.$route.name === "Home" && this.$route.query.q === this.search_)
        return;

      if (this.$route.name === "Home")
        this.$router.replace({query: {q: this.search_}});
      else
        this.$router.push({name: 'Home', query: {q: this.search_}})
    },

    async onSelect(val) {
      //console.log(val);
      if (val) {
        // From Google
        if (!val.nlp) {
          this.search_ = val.label;

          const place = await this.$http.get(`/map/search/place-details?place_id=${val.place_id}&session_token=${this.session_token}&coords=${this.$store.getters['coords']}`);
          if (place && place.data) {
            if (this.$session.exists()) {
              this.$http.post(`/map/users/${this.$session.get('id')}/search-history`, {
                  label: val.label,
                  coords: place.data.coords
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$session.get('jwt')
                  }
                });
            }
           // this.model.coords = place.data.coords;

            this.$store.commit("modes/setCurrentMarker", place.data.coords);

            if (this.$route.name === "Home") {
              this.$store.commit("setCoordinates", place.data.coords);
              this.$store.commit("setZoom", 14);
              this.$store.commit("setSearch", val.label);

              this.$store.dispatch("modes/getTransportsAround", {
                modes: this.$store.getters["modes/getEnabled"],
                keepOldData: false,
                jwt: this.$session.get('jwt'),
                messages: {
                  ERROR_MODE_NOT_LOADED: this.$t('view.errors.MODES_NOT_LOADED')
                }
              });
              //this.$router.replace({query: {...this.$route.query, /*q: this.model.label,*/ coords: `${place.data.coords.lat},${place.data.coords.lng}`, zoom: 14}});

            } else {
              this.$store.commit("setCoordinates", place.data.coords);
              this.$store.commit("setZoom", 14);
              this.$store.commit("setSearch", val.label);

              this.$store.dispatch("modes/getTransportsAround", {
                modes: this.$store.getters["modes/getEnabled"],
                keepOldData: false,
                jwt: this.$session.get('jwt'),
                messages: {
                  ERROR_MODE_NOT_LOADED: this.$t('view.errors.MODES_NOT_LOADED')
                }
              });
              this.$router.push({
                name: 'Home',
                query: {coords: `${place.data.coords.lat},${place.data.coords.lng}`, zoom: 14}
              });

            }
          }
          else
            this.goSearchNLP();
        }
        else
         this.goSearchNLP();

      }
    },

    searchAPI(val) {

      this.search_ = val;
      this.isLoading = true;
      if (!!val) {
        //clearTimeout(this._timerID);
        this._timerID = setTimeout(() => {

          if (val === this.search_) {
            this.isLoading = true;
            //console.log(val);
            this.$http(`/map/search/autocomplete?q=${encodeURI(val)}&session_token=${this.session_token}&coords=${this.$store.getters['coords']}`)
              .then((res) => {
                this.updateItems(res.data.predictions);
              })
              .catch(err => {
                console.log(err);
              })
              .finally(() => {
                this.isLoading = false;
              })
          } else
            this.isLoading = false;
        }, 500);
      }
      else {
        this.items = [];
        this.isLoading = false;
      }
    },

    goSearchNLP() {
      if (this.$route.name === "Home") {
        //this.$store.commit("setSearch", this.search);
        this.$router.replace({query: {q: this.search_}});
        this.$store.commit("setZoom", 14);
      }
      else
        this.$router.push({name: 'Home', query: {q: this.search_, zoom: 14}})
    },

    updateItems(newItems) {
      this.items = [...newItems.map((el) => {
        return  {
          label: el.label,
          place_id: el.place_id,
          types: el.types
        }
      }), {
        label: `${this.search_}`,
        nlp: true
        //coords: null
      }];

      this.showMenu = true;
    },

    generateSessionToken() {
      this.session_token = uuidv4();
    }
  },

  computed: {
    isMobile() {
      return this.$store.getters['isMobile'];
    },

    style() {
      if (this.isMobile) {
        return 'height: 48px !important; width: 40vw !important; padding-top: 10px !important;';
      }
      else
        return 'height: 48px !important; width: 200px !important; padding-top: 10px !important;';
    },
  },

  watch: {
    value: function (newValue, oldValue) {
      console.log(newValue);
      if (newValue) {
        this.items = [];
        this.model = newValue;
      }
    },

    /*
    search_(newValue) {
      this.searchAPI(newValue);
      this.showMenu = true;
    }*/
  }
}
</script>

<style>
.v-text-field--rounded > .v-input__control > .v-input__slot  {
  padding: 0px !important;
}

</style>